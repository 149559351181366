.navbar-dark .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.9) !important;
}
.newtext {
  text-align: justify;
}
.colortext {
  color: black;
}
.header-contacts .navbar {
  box-shadow: none;
}
.header a {
  line-height: 1.5;
}
.bord-line.true {
  border: solid 2px #00e4d8;
  border-radius: 5px;
}
div.round-gradient1.true {
  background-image: linear-gradient(to right, #00a5e5, #00e2d8);
  border-radius: 60px;
  color: #fff !important;
  border: solid 1px #00e4d8;
}
input[type="checkbox"].checked + label::after {
  content: "";
  position: absolute;
  width: 1.2ex;
  height: 0.4ex;
  background: rgba(0, 0, 0, 0);
  top: 0.9ex;
  left: 0.4ex;
  border: 3px solid #1062a4;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.accordion .card-header {
  padding: 0px;
  margin-bottom: 0;
  background-color: none;
  border-bottom: none;
}
.accordion .collapse li {
  list-style: none;
}
.accordian .accordion .title:hover {
  background-color: #fff;
}
.nav-tabs .active {
  background-image: linear-gradient(to right, #00a3e4, #00e3d8);
  border-color: #01a6e4;
  border: solid 1px #00e0d9;
  color: #fff !important;
}
.collapse ul li ol li {
  list-style: disc !important;
  margin-left: 20px;
}
.slide-btn {
  margin-top: 11.851852vmin !important;
}
.tooltip ul li,
.tooltip p {
  text-align: left;
}
.tooltip h4 {
  color: #00a6e6;
  text-align: left;
}
.small-img img {
  width: 150px;
}
.pro-list-item h4,
.pro-list-item p {
  text-align: left;
}
.font-we-650 {
  font-weight: 700;
}
.faq-to-pad-cont {
  padding-top: 15%;
}
.pt-40 {
  padding-top: 40px;
}
.blinking {
  animation: blinkingText 1.2s infinite;
  line-height: 1.5;
}
@keyframes blinkingText {
  0% {
    color: #00b5e2;
  }
  49% {
    color: #00b5e2;
  }
  60% {
    color: transparent;
  }
  99% {
    color: transparent;
  }
  100% {
    color: #00b5e2;
  }
}

span.cls_002 {
  font-family: "Montserrat", sans-serif;
  font-size: 70.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_002 {
  font-family: "Montserrat", sans-serif;
  font-size: 70.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_004 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_004 {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_006 {
  font-family: "Montserrat", sans-serif;
  font-size: 18.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_006 {
  font-family: "Montserrat", sans-serif;
  font-size: 18.1px;
  color: rgb(0, 0, 0);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
span.cls_007 {
  font-family: "Montserrat", sans-serif;
  font-size: 16.1px;
  color: rgb(14, 76, 100);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}
div.cls_007 {
  font-family: "Montserrat", sans-serif;
  font-size: 16.1px;
  color: rgb(14, 76, 100);
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
}

.welcometop {
  position: absolute;
  left: 50%;
  margin-left: -297px;
  top: 0px;
  width: 595px;
  height: 841px;
  border-style: none;
  overflow: hidden;
}

.welcomebody {
  position: absolute;
  left: 0px;
  top: 0px;
}

#welcomecontent {
  position: absolute;
  left: 34.95px;
}

#welcomecontent2 {
  position: absolute;
  left: 118.12px;
}
.pt-5,
.py-5 {
  padding-top: 3rem !important;
}
.MuiTable-root {
  border: 1px solid #c9ccd7;
}
.MuiTable-root th,
.MuiTable-root td {
  border: 1px solid #c9ccd7;
}
.badge {
  white-space: pre-wrap;
  color: #fff;
}
