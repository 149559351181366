@import "https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";
@font-face {
  font-family: "Montserrat", sans-serif;
  src: format("woff2"),
    url(../fonts/monumentextended-regular-webfont.woff) format("woff");
  font-weight: 400;
  font-style: normal;
}
html {
  font-size: 12px;
}
body {
  font-family: "Montserrat", sans-serif;
  color: #000;
  line-height: 1.5;
}
a,
a:hover {
  color: #5ca595;
}
a.text-primary:focus,
a.text-primary:hover {
  color: #5ca595 !important;
}
::selection {
  background-color: #00e3d8;
  color: #fff;
  text-shadow: none;
}
-webkit-::selection {
  background-color: #00e3d8;
  color: #fff;
  text-shadow: none;
}
::-moz-selection {
  background-color: #00e3d8;
  color: #fff;
  text-shadow: none;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  cursor: pointer;
  background: #00e3d8;
}
@media (min-width: 1200px) {
  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    max-width: 1200px;
  }
}
@media (min-width: 1600px) {
  .container-fluid {
    padding-left: 4.25rem;
    padding-right: 4.25rem;
  }
}
.full-height {
  min-height: 100vh;
}
.font-custom {
  font-family: monumentextended, sans-serif;
}
.text-primary {
  color: #00e3d8 !important;
}
.text-success,
a.text-success:hover {
  color: #0daae7 !important;
}
.text-underline {
  text-decoration: underline;
}
.text-md {
  font-size: 1.125rem;
}
.text-white a {
  color: #fff;
}
button:focus,
input:focus {
  outline: none;
}
.btn {
  padding: 0.7rem 1.5rem;
  border-radius: 500px;
  font-size: 0.9rem;
  font-weight: 500 !important;
}
.btn-success,
.btn-success:hover,
.btn-success.focus,
.btn-success:focus,
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  background-image: linear-gradient(to right, #00a3e4, #00e3d8);
  border-color: #01a6e4;
  border: 0px;
}
.btn-success {
  transition: box-shadow 0.3s ease-in-out;
  transition-delay: 0;
}
.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  box-shadow: rgba(92, 165, 149, 0.2) 0 0 0 6px;
}
.btn-sm {
  padding-top: 0.5rem;
  padding-bottom: 0.375rem;
  font-size: 1rem;
}
.btn-circle.btn-sm {
  padding-left: 0.4375rem;
  padding-right: 0.4375rem;
  min-width: calc(0.875rem + 1.5em + 2px);
}
.control-btn {
  margin-top: 2rem;
}
select.form-control {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA2NCA2NCc+PHBhdGggZmlsbD0nIzAwMDAwMCcgZD0nTTMyLDQ4LjFjLTEuMywwLTIuNC0wLjUtMy41LTEuM0wwLjgsMjAuN2MtMS4xLTEuMS0xLjEtMi43LDAtMy43YzEuMS0xLjEsMi43LTEuMSwzLjcsMEwzMiw0Mi44bDI3LjUtMjYuMSBjMS4xLTEuMSwyLjctMS4xLDMuNywwYzEuMSwxLjEsMS4xLDIuNywwLDMuN0wzNS41LDQ2LjVDMzQuNCw0Ny45LDMzLjMsNDguMSwzMiw0OC4xeicvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: 100% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-group-material {
  margin-bottom: 0.625rem;
  padding-top: 1rem;
  position: relative;
}
.form-group-material .label {
  position: absolute;
  left: 0;
  font-size: 1.125rem;
  color: #999;
  pointer-events: none;
  transform-origin: left top;
  transition: all 0.3s ease-in-out;
}
.form-group-material .label,
.form-group-material.a-form-group.active .label {
  transform: scale(0.77777778);
  top: 0;
}
.form-group-material.a-form-group .label {
  transform: scale(1);
  top: 1.5625rem;
}
.form-group-material .form-control {
  border-radius: 0;
  border: none;
  border-bottom: #999 1px solid;
  background-color: transparent;
  padding: 0.5rem 0;
  font-size: 1.125rem;
  color: #000;
}
.form-group-material .form-control:not(textarea) {
  height: calc(1.5em + 1rem + 1px);
}
.form-group-material .form-control:focus {
  outline: none;
  box-shadow: none;
  border-bottom-color: #000;
}
label.error {
  margin-top: 0.5rem;
  margin-bottom: 0;
  color: #00e3d8;
}
.form-control.error,
.form-control.error:focus {
  border-color: #00e3d8;
  color: #00e3d8;
}
.control-file {
  margin-top: 1.625rem;
  margin-bottom: 2.5rem;
  position: relative;
}
.control-file i {
  position: absolute;
  left: -0.25rem;
  top: 0.5625rem;
  font-size: 1.625rem;
}
.control-file .file-path-wrapper {
  overflow: hidden;
}
.control-file input.file-path {
  padding: 0 0 0 2.125rem;
  border: none;
  border-radius: 0;
  background: 0 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 1.125rem;
}
.control-file input.file-path::-moz-placeholder {
  color: #00e3d8;
  text-decoration: underline;
  opacity: 1;
  transition: color 0.3s ease;
}
.control-file:hover input.file-path::-moz-placeholder {
  color: #5ca595;
}
.control-file input.file-path:-ms-input-placeholder {
  color: #00e3d8;
  text-decoration: underline;
  transition: color 0.3s ease;
}
.control-file:hover input.file-path:-ms-input-placeholder {
  color: #5ca595;
}
.control-file input.file-path::-webkit-input-placeholder {
  color: #00e3d8;
  text-decoration: underline;
  transition: color 0.3s ease;
}
.control-file:hover input.file-path::-webkit-input-placeholder {
  color: #5ca595;
}
.control-file input[type="file"] {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 1.125rem;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}
.control-file input[type="file"]::-webkit-file-upload-button {
  display: none;
}
@-webkit-keyframes fadeZooming {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.6, 0.6, 0.6);
    transform: scale3d(0.6, 0.6, 0.6);
    margin-left: -12rem;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
@keyframes fadeZooming {
  from {
    opacity: 0;
    -webkit-transform: scale3d(0.6, 0.6, 0.6);
    transform: scale3d(0.6, 0.6, 0.6);
    margin-left: -12rem;
  }
  100% {
    opacity: 1;
    margin-left: 0;
  }
}
.fadeZooming {
  -webkit-animation-name: fadeZooming;
  animation-name: fadeZooming;
}
@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -6.5rem, 0);
    transform: translate3d(0, -6.5rem, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -6.5rem, 0);
    transform: translate3d(0, -6.5rem, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}
@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10rem, 0);
    transform: translate3d(0, 10rem, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 10rem, 0);
    transform: translate3d(0, 10rem, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
@-webkit-keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10rem, 0, 0);
    transform: translate3d(-10rem, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-10rem, 0, 0);
    transform: translate3d(-10rem, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft;
}
@-webkit-keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10rem, 0, 0);
    transform: translate3d(10rem, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRight {
  from {
    opacity: 0;
    -webkit-transform: translate3d(10rem, 0, 0);
    transform: translate3d(10rem, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRight {
  -webkit-animation-name: fadeInRight;
  animation-name: fadeInRight;
}
.preloader {
  position: fixed;
  overflow: hidden;
  z-index: 999999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #f5f4f0;
  text-align: center;
}
.three-bounce {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.three-bounce > div {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #c06451;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out both;
  animation: bouncedelay 1.4s infinite ease-in-out both;
}
.three-bounce .one {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.three-bounce .two {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.header {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  margin-top: 0;
}
.header .container-fluid {
  color: #000;
  transition: color 0.3s ease-in-out;
  text-align: center;
}
@media (min-width: 769px) {
  .header {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 900;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
    rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
}
.header-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 0;
  z-index: -1;
  background-color: #fff;
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
  transition: 0.3s ease-in-out;
}
.header-shadow .header-bg {
  height: 100%;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.1);
}
.brand {
  float: left;
  margin-right: 1rem;
  white-space: nowrap;
  font-size: 1.5rem;
  text-transform: uppercase;
}
.brand a {
  color: inherit;
  text-decoration: none;
}
.header-content {
  margin-left: 17.25%;
  margin-right: 4%;
  padding-top: 3px;
}
.header-tagline {
  padding-top: 2px;
  font-size: 0.875rem;
  line-height: 1.28571429;
  color: #999;
  font-weight: 700;
  text-transform: uppercase;
  transition: color 0.3s ease-in-out;
}
.header-contacts {
  font-size: 0.875rem;
}
.header-contact-item,
.header-contact-divider {
  display: inline-block;
  vertical-align: middle;
}
.header-contact-divider {
  margin-left: 1.875rem;
  margin-right: 1.875rem;
  opacity: 0.2;
}
.phone-link,
.phone-link:hover,
.mail-link,
.mail-link:hover {
  color: inherit;
  text-decoration: none;
}
.phone-link {
  cursor: default;
}
.nav-toggle-btn {
  background: 0 0;
  border: none;
  border-radius: 0;
  left: 10px;
  top: 30px;
  outline: none;
  float: left;
  position: fixed;
  z-index: 995;
  font-weight: 700;
  text-transform: uppercase;
  color: inherit;
}
.nav-toggle-btn:hover {
  color: #b11d11;
}
.nav-toggle-btn:focus {
  outline: none;
}
.nav-toggle {
  display: block;
  width: 32px;
}
.nav-toggle .stick {
  display: block;
  width: 100%;
  border-radius: 3px;
  height: 2px;
  background: #000;
  transition: all 0.3s;
  position: relative;
}
.nav-toggle .stick + .stick {
  margin-top: 5px;
}
.body-menu-opened .nav-toggle .stick-1 {
  animation: ease 0.5s top forwards;
}
.nav-toggle .stick-1 {
  animation: ease 0.5s top-2 forwards;
}
.body-menu-opened .nav-toggle .stick-2 {
  animation: ease 0.5s bottom forwards;
}
.nav-toggle .stick-2 {
  animation: ease 0.5s bottom-2 forwards;
}
@keyframes top {
  0% {
    top: 0;
    transform: rotate(0);
  }
  50% {
    top: 6px;
    transform: rotate(0);
  }
  100% {
    top: 6px;
    transform: rotate(45deg);
  }
}
@keyframes top-2 {
  0% {
    top: 6px;
    transform: rotate(45deg);
  }
  50% {
    top: 6px;
    transform: rotate(0deg);
  }
  100% {
    top: 0;
    transform: rotate(0deg);
  }
}
@keyframes bottom {
  0% {
    bottom: 0;
    transform: rotate(0);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 6px;
    transform: rotate(135deg);
  }
}
@keyframes bottom-2 {
  0% {
    bottom: 6px;
    transform: rotate(135deg);
  }
  50% {
    bottom: 6px;
    transform: rotate(0);
  }
  100% {
    bottom: 0;
    transform: rotate(0);
  }
}
.menu {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 990;
  background: #fff;
  max-width: 100%;
  width: 100%;
  padding: 4.25rem 4.375rem 4.1875rem;
  display: flex;
  flex-direction: column;
  transition: transform 0.4s ease-in-out;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
}
.body-menu-opened .menu {
  -webkit-transform: translateX(0%);
  transform: translateX(0%);
}
.hide-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 100%;
  background: rgba(0, 0, 0, 0.65);
  z-index: 980;
}
.body-menu-opened .hide-menu {
  bottom: 0;
}
@media (min-width: 576px) {
  .menu {
    width: 27.5rem;
  }
  .modal-dialog {
    max-width: 750px;
  }
}
.menu .menu-lang,
.menu .menu-main,
.menu .social,
.menu .menu-footer {
  opacity: 0;
  transform: translateY(3rem);
  transition: all 0.5s ease-in-out;
}
.menu .menu-lang {
  transition-delay: 0.2s;
}
.menu .menu-main {
  transition-delay: 0.4s;
}
.menu .social {
  transition-delay: 0.6s;
}
.menu .menu-footer {
  transition-delay: 0.8s;
}
.body-menu-opened .menu .menu-lang,
.body-menu-opened .menu .menu-main,
.body-menu-opened .menu .social,
.body-menu-opened .menu .menu-footer {
  opacity: 1;
  transform: translateY(0);
}
.menu .menu-lang {
  padding-bottom: 5.875rem;
  padding-bottom: 8.7037037vmin;
}
.menu-lang {
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 600;
}
.menu-lang-item {
  display: inline-block;
  margin-right: 1.5rem;
  color: #a3a4a8;
  text-decoration: none;
}
.menu-lang-item.active,
.menu-lang-item:hover {
  color: #1f2044;
  text-decoration: none;
}
.menu-main {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-size: 1rem;
}
.menu-main > ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.menu-main > ul > li {
  margin-bottom: 1.1875rem;
  padding-left: 2px;
}
.menu-main a,
.menu-main a:hover {
  color: #1f2044;
  text-decoration: none;
}
.menu-main .active > a,
.menu-main a:hover {
  color: #00e3d8;
}
.menu-main a:after {
  content: ".";
  opacity: 0;
  display: inline-block;
  transform: translate(2rem, 0);
  transition: all 0.3s ease-in-out;
}
.menu-main .active a:after {
  transform: translate(0, 0);
  opacity: 1;
}
.menu-footer {
  padding-top: 3rem;
}
.menu-copyright {
  margin-top: 2rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 700;
  color: #999;
}
.content a {
  transition: color 0.3s ease;
}
@media (max-width: 575px) {
  .homepage-personal .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .homepage-personal .slide-personal-projects .container {
    padding-right: 53px;
  }
}
#pp-nav.right {
  right: 0;
}
#pp-nav li {
  width: auto;
  height: auto;
  margin: 0.5rem;
}
#pp-nav li a {
  display: block;
  padding: 0.375rem;
}
#pp-nav span {
  display: block;
  position: static;
  width: 0.625rem;
  height: 0.625rem;
  display: none;
  border: none;
  background-color: #e3e3e3;
  transition: background-color 0.3s ease-in-out;
}
#pp-nav a:hover span {
  background-color: #aaa;
}
#pp-nav li .active span {
  background-color: #00e3d8;
}
@media (min-width: 1600px) {
  #pp-nav.right {
    right: 3.5rem;
  }
}
.pp-scrollable {
  background-color: #fff;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.slide-dark {
  background-color: #ffffff;
}
.slide-num {
  margin-bottom: 1rem;
  position: relative;
  z-index: 200;
  font-family: monumentextended, sans-serif;
  font-size: 1.125rem;
  color: #999;
  transition: color 0.3s ease-in-out;
}
@media (min-width: 1400px) {
  .slide-num {
    position: fixed;
    left: 15px;
    top: 29.62962963vmin;
    z-index: 100;
    margin-bottom: 0;
  }
}
@media (min-width: 1600px) {
  .slide-num {
    left: 4.25rem;
  }
}
.slide-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  padding-top: 7.75rem;
  padding-bottom: 3.25rem;
  position: relative;
}
.slide-container > .container {
  position: relative;
  z-index: 200;
}
.slide-title {
  margin-bottom: 2rem;
  font-size: 9vmin;
  font-family: "Montserrat", sans-serif;
  line-height: 1.3;
  color: #01a8e4;
  font-weight: bold;
}
.video-btn-top {
  padding-top: 30% !important;
}
.video-btn-top a {
  color: #fff;
}
.video-btn-top a:hover {
  color: #024460;
}
.banner-button {
  background-image: linear-gradient(90deg, #00e3d8, #00e3d8) !important;
  border: 1px solid #00e0d9 !important;
}
@media (min-width: 769px) {
  .slide-title {
    font-size: 7vmin;
  }
}
.slide-title-lg {
  margin-left: -0.25rem;
  font-size: 6.875rem;
}
.slide-title-sub {
  margin-bottom: 1.5rem;
  font-size: 2.375rem;
  font-family: "Montserrat", sans-serif;
  line-height: 1.45454545;
  color: #757575;
  font-weight: 400;
}
.text-white .slide-title-sub {
  color: #fff;
}
.slide-title-sub-sm {
  font-size: 1.125rem;
  line-height: 1.55555556;
}
.slide-title-sub-md {
  font-size: 1.25rem;
}
.slide-title-info {
  font-size: 3.5rem;
  font-weight: 700;
  color: #3db2e4;
}
.slide-descr {
  color: #999;
  font-size: 1rem;
  line-height: 1.66666667;
}
.text-white .slide-descr {
  color: #fff;
}
.slide-descr-intro {
  margin-right: 18%;
}
.slide-btn {
  margin-top: 6.85185185vmin;
}
.section:not(.active) .animate-element {
  animation-name: none;
}
.section.active .animate-element {
  animation-duration: 0.7s;
  animation-fill-mode: both;
}
.section.active .delay1 {
  animation-delay: 0.1s;
}
.section.active .delay2 {
  animation-delay: 0.2s;
}
.section.active .delay3 {
  animation-delay: 0.3s;
}
.section.active .delay4 {
  animation-delay: 0.4s;
}
.section.active .delay5 {
  animation-delay: 0.5s;
}
.section.active .delay6 {
  animation-delay: 0.6s;
}
.section.active .delay7 {
  animation-delay: 0.7s;
}
.section.active .delay8 {
  animation-delay: 0.8s;
}
.section.active .delay9 {
  animation-delay: 0.9s;
}
.section.active .delay10 {
  animation-delay: 1s;
}
.circle-light {
  background-image: url(../img/left-img.png);
  background-repeat: no-repeat;
  background-position: left bottom;
}
.circle-golden {
  background-color: #00e3d8;
}
.circle-brown {
  background-color: #c06451;
}
.circle-green {
  background-color: #5ca595;
}
.transformLeft {
  transform: translate(-100%, 0);
  opacity: 0;
}
.transformRight {
  transform: translate(100%, 0);
  opacity: 0;
}
.active .transformLeft,
.active .transformRight {
  transform: translate(0%, 0);
  opacity: 1;
}
.slide-personal-intro {
  background-image: url(../img/bg-personal-intro.webp);
  background-size: cover;
}
.slide-bg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  overflow: hidden;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}
.slide-contacts-circle112 {
  width: 50.5625rem;
  height: 30.5625rem;
  top: 38.94444444%;
  left: -3.4375%;
}
.slide-photo {
  position: absolute !important;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}
.slide-intro-circle1 {
  width: 10.0625rem;
  height: 10.0625rem;
  left: -4.5rem !important;
  top: 45.46296296% !important;
  bottom: auto;
  right: auto;
}
.slide-intro-circle2 {
  width: 70.625rem;
  height: 70.625rem;
  left: 58.85416667% !important;
  top: 46.66666667% !important;
  bottom: auto;
  right: auto;
}
.slide-services-circle1 {
  width: 8.3125rem;
  height: 8.3125rem;
  right: -3.9375rem !important;
  top: 18.51851852% !important;
  bottom: auto;
  left: auto !important;
}
.slide-services-circle2 {
  width: 55rem;
  height: 55rem;
  left: -8.4375% !important;
  top: 49.44444444% !important;
  bottom: auto;
  right: auto;
}
.slide-title-personal-services {
  margin-bottom: 5.55555556vmin;
  line-height: 0.93636364;
}
.slide-personal-services .slide-title-info {
  margin-top: 1.25rem;
  margin-bottom: 8.33333333vmin;
}
.service-list {
  margin-bottom: -1.75rem;
}
.service-item {
  margin-bottom: 3.75rem;
}
.service-item-title {
  margin-bottom: 0.5625rem;
}
.service-item-more {
  margin-top: 1.6875rem;
}
.slide-descr-personal-services {
  margin-right: 10%;
  margin-top: 2.375rem;
  margin-bottom: 2rem;
}
@media (min-width: 769px) {
  .slide-descr-personal-services {
    margin-top: 22.375rem;
    margin-bottom: 0;
  }
}
@media (min-width: 992px) {
  .slide-descr-personal-services {
    margin-right: 35%;
    margin-top: 14.375rem;
  }
}
.carousel-project-personal {
  min-height: 100%;
  display: flex !important;
}
.carousel-project-personal .owl-stage-outer {
  display: flex;
  overflow: visible;
}
.carousel-project-personal.owl-carousel .owl-stage {
  display: flex;
  flex-wrap: nowrap;
}
.carousel-project-personal.owl-carousel .owl-item {
  height: 100%;
  float: none;
  min-height: 100%;
}
.carousel-project-personal .carousel-project-item {
  height: 100%;
}
.owl-theme .owl-nav {
  margin-top: 0;
}
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.carousel-nav button {
  border: none;
  background-color: transparent;
  padding: 0;
  margin: 0;
  font-size: 2.375rem;
  transition: all 0.3s ease-in-out;
  color: #999;
}
.owl-theme .owl-nav [class*="owl-"].disabled:hover,
.carousel-nav button.disabled:hover {
  color: #999;
}
.owl-theme .owl-nav [class*="owl-"]:not(.disabled):hover,
.carousel-nav button:not(.disabled):hover {
  color: #555;
}
.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  background-color: transparent;
}
.carousel-project-personal.owl-carousel .owl-nav button {
  position: absolute;
  top: 50%;
  z-index: 100;
  transform: translate(0, -50%);
  width: 4.375rem;
  color: rgba(255, 255, 255, 0.6);
}
.carousel-project-personal.owl-carousel .owl-nav button.owl-prev {
  left: -0.5rem;
}
.carousel-project-personal.owl-carousel .owl-nav button.owl-next {
  right: 1rem;
}
.carousel-project-personal.owl-carousel .owl-nav button:not(.disabled):hover {
  color: rgba(255, 255, 255, 0.9);
}
@media (min-width: 1500px) {
  .carousel-project-personal.owl-carousel .owl-nav button,
  .carousel-project-personal.owl-carousel .owl-nav button.owl-prev {
    left: 50%;
    right: auto;
    transform: translate(-50%, -50%);
  }
  .carousel-project-personal.owl-carousel .owl-nav button.owl-prev {
    margin-left: -46rem;
  }
  .carousel-project-personal.owl-carousel .owl-nav button.owl-next {
    margin-left: 46rem;
  }
}
.carousel-project-item {
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.carousel-project-personal .slide-title {
  margin-bottom: 1.625rem;
}
.project-date {
  margin-top: 0.375rem;
  font-size: 0.875rem;
  font-weight: 700;
  text-transform: uppercase;
}
.slide-descr-projects {
  margin-bottom: 1.875rem;
}
.slide-btn-projects {
  margin-top: 0;
}
.slide-btn-projects .btn {
  margin-right: 2.25rem;
}
.slide-projects-more {
  display: inline-block;
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
  text-decoration: underline;
}
.slide-projects-more:hover {
  text-decoration: none;
}
.slide-personal-awards .slide-bg {
  background-image: url(../img/bg-personal-awards.png);
}
.slide-awards-circle1 {
  width: 8.125rem;
  height: 8.125rem;
  left: -4.0625rem !important;
  top: 50.92592593% !important;
  bottom: auto !important;
  right: auto !important;
}
.slide-awards-circle2 {
  width: 45.3125rem;
  height: 45.3125rem;
  right: -15.625% !important;
  top: 82.59259259% !important;
  bottom: auto !important;
  left: auto !important;
}
.award-item {
  background: #ffffff;
  border-radius: 1rem 1rem 3rem 1rem;
  padding: 2.1875rem 0rem;
}
.award-list .award-item {
  margin-top: 0px;
}
.award-item-date {
  margin-bottom: 0.625rem;
  font-size: 0.875rem;
  color: #999;
  text-transform: uppercase;
  font-weight: 700;
}
.award-item-logo {
  margin-bottom: 0px;
  height: 7rem;
  display: block;
  overflow: hidden;
  align-items: center;
}
.award-item-logo img {
  max-width: 100%;
  max-height: 100%;
}
.award-item-title {
  margin-bottom: 0.5rem;
}
.award-item-descr {
  margin-bottom: 0rem;
}
.award-item-more {
  font-size: 0.9rem;
}
@media (min-width: 992px) {
  .award-list .award-item {
    height: calc(100% - 4rem);
  }
}
.experience-item:not(:first-child) {
  border-top: #e1ddd6 1px solid;
}
.experience-item {
  padding-top: 1.625rem;
}
.experience-item-company,
.experience-item-title,
.experience-item-descr {
  margin-bottom: 1.625rem;
}
.experience-item-title {
  margin-right: 1rem;
}
.experience-item-date {
  margin-top: 0.3125rem;
  font-size: 1.125rem;
  text-transform: uppercase;
  font-weight: 700;
}
.experience-item-descr {
  margin-top: 2px;
  min-height: 5em;
}
.company-blue {
  color: #1774eb;
}
.company-pink {
  color: #f73163;
}
.company-green {
  color: #1dd05d;
}
.carousel-experience {
  position: relative;
}
.carousel-nav {
  margin-right: -0.625rem;
}
.carousel-nav .owl-next {
  margin-left: 0.5rem;
}
.slide-personal-experience .slide-btn {
  margin-top: 3.88888889vmin;
}
@media (min-width: 769px) {
  .carousel-nav .owl-next {
    margin-left: 2rem;
  }
}
.slide-personal-clients .slide-bg {
  background-image: url(../img/bg-personal-clients.png);
}
.slide-personal-clients .slide-title-info {
  margin-bottom: 12.40740741vmin;
}
.title-mini {
  font-size: 1.1rem;
  color: #999;
  font-weight: 500;
}
.slide-personal-clients .title-mini {
  margin-bottom: 3.375rem;
}
.client-icon {
  width: 3.75rem;
  margin-right: 2.5rem;
}
.client-item {
  margin-bottom: 2.75rem;
}
.client-item-title {
  margin-bottom: 0.625rem;
  font-size: 2.125rem;
  font-family: "Montserrat", sans-serif;
  line-height: 0.94117647;
}
.clients-photo {
  height: 0;
  margin-top: 0rem;
  margin-bottom: 1rem;
  position: relative;
}
.clients-photo-item {
  width: 4.375rem;
}
.clients-photo .clients-photo-item {
  position: absolute;
  width: 14.56140351%;
}
.clients-photo-item > .inside {
  display: block;
  height: 0;
  padding-bottom: 100%;
  background-color: #f5f4f0;
  border-radius: 50rem;
  overflow: hidden;
  position: relative;
}
.clients-photo-item img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: block;
  max-width: 100%;
  max-height: 100%;
}
.clients-photo .clients-photo-item1 {
  width: 19.29824561%;
  top: 43.05084746%;
  left: 6.31578947%;
}
.clients-photo .clients-photo-item2 {
  top: 0%;
  left: 57.54385965%;
}
.clients-photo .clients-photo-item3 {
  top: 48.81355932%;
  right: 0;
}
.clients-photo-item3 img {
  width: 60.24096386%;
  margin-top: 2px;
}
.clients-photo .clients-photo-item4 {
  top: 11.52542373%;
  left: 11.57894737%;
}
.clients-photo-item4 img {
  width: 34.93975904%;
}
.clients-photo .clients-photo-item5 {
  width: 31.92982456%;
  bottom: 0;
  right: 3.50877193%;
}
.clients-photo .clients-photo-item6 {
  width: 17.54385965%;
  bottom: 6.10169492%;
  left: 31.57894737%;
}
.clients-photo-item6 img {
  width: 31%;
}
.clients-photo .clients-photo-item7 {
  width: 49.12280702%;
  top: 23.05084746%;
  left: 31.92982456%;
}
.clients-photo .clients-photo-item8 {
  width: 12.28070175%;
  top: 73.55932203%;
  left: 6.31578947%;
}
.slide-testimonials-circle1 {
  width: 10.5625rem;
  height: 10.5625rem;
  left: -14.89361702%;
  top: -11.70212766%;
}
.slide-testimonials-circle2 {
  width: 17.5rem;
  height: 17.5rem;
  left: 64.893617023%;
  top: 65.95744681%;
}
.slide-testimonials-circle3 {
  width: 6.875rem;
  height: 6.875rem;
  left: 27.55208333%;
  top: 100%;
  margin-top: -3.75rem;
}

.testimonials-item-avatar-container {
  margin-bottom: 3.25rem;
}
.testimonial-item-avatar,
.testimonial-item-company {
  display: inline-block;
  vertical-align: top;
  margin-right: -1.25rem;
  width: 4.375rem;
}
.avatar > .inside {
  display: block;
  height: 0;
  padding-bottom: 100%;
  border-radius: 500px;
  overflow: hidden;
  background-position: 50% 30%;
  background-repeat: no-repeat;
  background-size: cover;
}
.testimonials-item-text {
  margin-bottom: 3.125rem;
}
.testimonials-item-rating {
  margin-bottom: 0.875rem;
  color: #ffc705;
}
.testimonials-item-author {
  font-size: 1.125rem;
  font-weight: 700;
  text-transform: uppercase;
}
.carousel-testimonial.owl-theme .owl-nav {
  margin-top: 3.625rem;
  margin-left: -0.5rem;
  text-align: left;
}
.carousel-testimonial.owl-carousel .owl-nav button.owl-prev {
  margin-right: 1.875rem;
}
.slide-personal-testimonials .video-link {
  margin-top: 0.5rem;
}
.video-link {
  position: relative;
}
.video-link > .inside {
  display: block;
  position: relative;
  z-index: 100;
  height: 0;
  padding-bottom: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2rem 2rem 5rem 2rem;
}
.center-icon {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  color: #fff;
  font-size: 2.75rem;
}
.center-icon i {
  display: block;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}
a:hover .center-icon i {
  transform: scale(1.2);
}
.video-link-descr {
  margin-left: 2.5rem;
  margin-top: 1.375rem;
  position: relative;
  font-style: italic;
}
.slide-contacts-circle1 {
  width: 50.5625rem;
  height: 20.5625rem;
  top: 60.94444444%;
  left: -3.4375%;
}
.slide-contacts-circle2 {
  width: 8.125rem;
  height: 8.125rem;
  top: 15.55555556%;
  right: -2.96875%;
  left: auto;
}
.contact-personal-card {
  display: flex;
  flex-direction: column;
}
.contact-personal-card-title {
  height: 100%;
}
.contact-personal-card .title-mini {
  margin-bottom: 2.875rem;
}
.contact-personal-card .slide-title-sub {
  margin-bottom: 1.6875rem;
}
.slide-personal-contacts .slide-title-info {
  margin-bottom: 2.625rem;
}
.contact-personal-form {
  margin-bottom: 0.25rem;
}
.social {
  margin: 0;
  padding: 0;
  list-style: none;
  font-size: 1.3125rem;
}
.social li {
  display: inline-block;
  vertical-align: middle;
  margin-right: 1rem;
}
.social a {
  color: #999;
  transition: color 0.3s ease;
}
.social a:hover {
  color: #000;
}
.social-fixed {
  position: fixed;
  z-index: 300;
  left: 15px;
  bottom: 0;
}
.social-fixed li {
  display: block;
  margin-right: 0;
  margin-bottom: 1.375rem;
}
.copyright {
  text-align: right;
  color: #999;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}
.copyright-fixed {
  position: fixed;
  right: 15px;
  bottom: 1.5rem;
  z-index: 300;
}
@media (min-width: 1600px) {
  .social-fixed {
    left: 4.25rem;
    bottom: 2rem;
  }
  .copyright-fixed {
    right: 4.25rem;
    bottom: 3.5rem;
  }
}
.body-bg-dark .header:not(.header-shadow) .container-fluid {
  color: #fff;
}
.body-bg-dark .header:not(.header-shadow) .header-tagline,
.body-bg-dark .copyright,
.body-bg-dark .slide-num,
.body-bg-dark .footer .social a,
.body-copyright-light .copyright {
  color: rgba(255, 255, 255, 0.6);
}
.body-bg-dark .social a:hover {
  color: #fff;
}
.body-bg-dark .header:not(.header-shadow) .nav-toggle .stick,
.body-bg-dark #pp-nav li a:not(.active) span {
  background: #fff;
}
.body-menu-opened .body-bg-dark .nav-toggle .stick {
  background: #000;
}
.modal-content {
  border: none;
  border-radius: 1rem 1rem 1rem 1rem;
}
.modal-header {
  padding-top: 2rem;
  padding-bottom: 0.5rem;
  border-bottom: none;
}
.modal-header,
.modal-body,
.modal-footer {
  padding-left: 2rem;
  padding-right: 2rem;
}
.modal-body {
  padding-bottom: 2rem;
}
.modal-title {
  font-family: "Montserrat", sans-serif;
  font-size: 2rem;
  color: #00e3d8;
  text-transform: uppercase;
}
.modal-header .close {
  padding: 0.5rem;
  position: relative;
  top: -0.5rem;
  right: -0.5rem;
  font-size: 1.5rem;
  line-height: 0.75;
}
.message {
  display: none;
}
@media (min-width: 768px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 992px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1280px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 1600px) {
  html {
    font-size: 16px;
  }
}
.font-sz {
  font-size: 0.8rem;
  color: #616161;
}
.font-sz a {
  font-size: 0.8rem;
  color: #616161;
  text-decoration: none;
}
.btm-bor {
  border-bottom: solid 1px #c4c4c4;
}
.slide-title1 {
  font-size: 4.3vmin;
  font-weight: bold;
  color: #1b2c38 !important;
  margin-bottom: 1.6rem;
}
.light-gr {
  color: #00ade2 !important;
  font-weight: 600;
}
.para-text {
  color: #212121 !important;
}
.the-fast {
  font-size: 2rem;
  background: -webkit-linear-gradient(#00a4e5, #00e4d7) !important;
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.slide-personal-intro {
  background-image: url(../img/bg-personal-intro.webp);
  background-size: cover;
}
.slide-intro-man1 {
  background-image: url(../img/how-we-1.webp);
  background-position: 50% 50%;
  background-color: #f5f5f5;
}

.no-surprise {
  background-image: url(../img/no-surprise.webp);
  background-position: 50% 50%;
}
.slide-intro-man2 {
  background-image: url(../img/how-we-2.webp);
  background-position: 50% 50%;
}
.slide-intro-man3 {
  background-image: url(../img/how-we-3.webp);
  background-position: 50% 50%;
}
.slide-intro-man4 {
  background-image: url(../img/how-we-2.webp);
  background-position: 50% 50%;
  background-color: #f5f5f5;
}
.slide-int1 {
  background-image: url(../img/bg-personal-project-1.webp);
  background-position: 50% 50%;
  background-color: #f5f5f5;
}
.slide-int2 {
  background-image: url(../img/caring-eye.webp);
  background-position: 50% 50%;
}
.slide-app-bg {
  background-image: url(../img/app-bg-home.webp);
  background-position: 50% 50%;
}
.slide-int3 {
  background-image: url(../img/bg-personal-project3.jpg);
  background-position: 50% 50%;
  background-color: #f5f5f5;
}
.slide-int4 {
  background-image: url(../img/bg-personal-project4.jpg);
  background-position: 50% 50%;
}
.caring-eye {
  background-image: url(../img/caring-eye.webp);
  background-position: 50% 50%;
}
.bg-blue-test {
  background-image: linear-gradient(to right, #00ace3, #00e4d7);
}
.col-black-test {
  color: #000 !important;
  font-weight: 600;
}
.font-small-sing {
  font-size: 1.5rem;
  font-weight: 500;
}
.client-name {
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}
.text-whi {
  color: #fff;
}
.left-img-new {
  background-image: url(../img/left-img-new.webp);
  background-repeat: no-repeat;
  background-position: left bottom;
}
.right-img {
  background-image: url(../img/right-img.webp);
  background-repeat: no-repeat;
  background-position: right bottom;
}
.bg-grey-test {
  background-color: #f5f5f5;
}
.ftr-prod {
  font-size: 1rem;
  color: #757575;
  margin-bottom: 2rem;
}
.non-list {
  list-style: none;
  padding-left: 0px;
}
.non-list li {
  color: #ffffff;
  margin-bottom: 10px;
  font-size: 0.9rem;
}
.ftr-prod1 {
  font-size: 0.9rem;
  color: #757575;
  margin-bottom: 15px;
}
.g-color {
  color: #757575;
  font-size: 1.1rem;
  padding-bottom: 2.3rem;
}
.whi-color {
  color: #e0e0e0;
  font-weight: 500;
  font-size: 1.5rem;
}
.high-fix {
  height: 100px;
  padding-top: 40px;
}
.round-gradient1 {
  border: solid 1px #757575;
  border-radius: 60px;
}
.pl-022 {
  padding-left: 0px;
}
.ur-home-li {
  font-size: 1rem;
}
.ur-home-hed {
  font-weight: 500;
  padding-top: 5px;
  color: #fff;
  font-size: 1.1rem;
}
.bg-inp {
  width: 20px;
  height: 20px;
  border: solid 1px #00cfdc !important;
}
.round-gradient1 {
  color: #e6e6e6;
}
.round-gradient1:hover {
  background-image: linear-gradient(to right, #00a5e5, #00e2d8);
  border-radius: 60px;
  color: #fff !important;
  border: solid 1px #00e4d8;
}
.line-he-bann {
  line-height: 1.2 !important;
  margin-bottom: 0.6rem !important;
}
.bann-text-tp {
  padding-top: 10rem !important;
}
.font-weig-top {
  font-weight: 600 !important;
}
.black-col-sub {
  color: #000 !important;
}
.mb-mb1 {
  margin-bottom: 1.9rem !important;
}
.form-g-m {
  padding-top: 3rem !important;
}
.investiga {
  font-weight: 500;
  font-size: 1.6rem;
}
.ico-fo {
  color: #666;
  padding: 0rem 0.6rem;
}
.dropdown:hover > .dropdown-menu {
  display: block;
}
.top-list-one {
  font-size: 0.875rem;
  margin-bottom: 0px !important;
  color: #000;
  font-weight: 400;
}
.top-list-one a {
  color: #000;
}
.top-list-one a:hover {
  color: #333;
  text-decoration: none;
}
.list-inline-item:not(:last-child) {
  margin-right: 1rem;
}
.legal-pri {
  padding-top: 15rem !important;
}
.pl-section-two {
  padding-left: 7rem;
}
@media (min-width: 1910px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1600px;
  }
  /*.slide-intasd {
    background-image: url(../img/left-img1.png) !important;
    background-position: 50% 50%;
    
}*/
  .legal-pri {
    padding-top: 15rem !important;
  }
  .line-he-bann {
    line-height: 1.2 !important;
    margin-bottom: 0.8rem !important;
  }
  .the-fast {
    font-size: 4rem;
    line-height: 5.5rem;
    background: -webkit-linear-gradient(#00a4e5, #00e4d7) !important;
    -webkit-background-clip: text !important;
    -webkit-text-fill-color: transparent !important;
  }

  /*.navbar-dark .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.9) !important;
    font-weight: 400;
  }*/
  .navbar-dark .navbar-nav .active > .nav-link,
  .navbar-dark .navbar-nav .nav-link.active,
  .navbar-dark .navbar-nav .nav-link.show,
  .navbar-dark .navbar-nav .show > .nav-link {
    color: #6d6d6d;
  }
  .navbar-dark .navbar-nav .nav-link:focus,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, 0.75);
  }
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.9);
    font-weight: 400;
  }
  .drop-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.05);
    border-radius: 0rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }
  .dropdown-item {
    display: block;
    width: 100%;
    padding: 0.5rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    font-size: 0.8rem;
  }
  .dropdown-item:focus,
  .dropdown-item:hover {
    color: #ffffff;
    text-decoration: none;
    background-color: #00a7e3;
    font-size: 0.8rem;
  }
}
@media (max-width: 768px) {
  .menu-show {
    display: block;
  }
}
@media (min-width: 769px) {
  .menu-show {
    display: none;
  }
  .choose-sub-padd {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
}
@media (max-width: 769px) {
  .tp-menu-show {
    display: none;
  }
  .slide-int1 {
    background-image: none;
    background-position: 50% 50%;
    background-color: #f5f5f5;
  }
  .slide-int2 {
    background-image: none;
    background-position: 50% 50%;
  }
  .slide-int3 {
    background-image: none;
    background-position: 50% 50%;
    background-color: #f5f5f5;
  }
  .slide-int4 {
    background-image: none;
    background-position: 50% 50%;
  }
  .slide-intro-man4 {
    background-image: none;
    background-position: 50% 50%;
    background-color: #f5f5f5;
  }
  .slide-intro-man1 {
    background-image: none;
    background-position: 50% 50%;
  }
  .slide-intro-man3 {
    background-image: none;
    background-position: 50% 50%;
  }
  .sh-img {
    display: block;
  }
  .pl-022 {
    padding-left: 20px;
    padding-top: 10px;
  }
  .slide-container {
    width: 100%;
    min-height: 100%;
    display: inline;
    align-items: center;
    padding-top: 7.75rem;
    padding-bottom: 3.25rem;
    position: relative;
  }
  .pt-120 {
    padding-top: 120px;
  }
  .pt-gyap {
    padding-top: 0px;
  }
  .pt-gyap-home {
    padding-top: 0px;
  }
  .pl-section-two {
    padding-left: 0rem;
  }
  .client-icon {
    width: auto;
    margin-right: 0rem;
  }
  .sub-bg-two {
    background: linear-gradient(
      90deg,
      rgb(245, 245, 245) 50%,
      rgb(245, 245, 245) 50%
    ) !important;
    z-index: 2;
  }
  .building-svg {
    display: none;
  }
  .building-left {
    display: none;
  }
  .pt-fast-mob {
    padding-top: 33% !important;
  }
  .btm-conti-rt {
    text-align: center !important;
  }
  .mar-left-log {
    margin-left: 46px !important;
  }
  .ico-fo {
    color: #666;
    padding: 0rem 0.1rem;
  }
  .logo-wi-mob {
    width: 80px !important;
  }
  .pb-30-con {
    padding-bottom: 30px;
  }
  .wid-30-per {
    width: 20% !important;
  }
  .wid-30-per-in {
    width: 20%;
  }
  .wid-70-per {
    width: 80%;
  }
  .wid-70-per-in {
    width: 78%;
    padding-right: 0px;
  }
  .pr-0-inn {
    padding-right: 0px !important;
  }
  .safe-ico-pad {
    padding-left: 2rem !important;
  }
  .pb-30-min {
    margin-bottom: 50px;
  }
  .slide-btn-projects .btn {
    margin-right: 0rem;
  }

  .btm-mar-sort {
    margin-bottom: 15px;
  }
  .text-left-sort {
    padding-left: 0px;
    padding-right: 0px;
  }
  .text-left-sort {
    text-align: left !important;
  }
  .pad-lt-srt {
    padding-left: 0px;
  }
  .padd-tp-srt {
    padding-top: 50px;
  }
  .padd-tp-srt-btm {
    padding-top: 20px;
  }
  .blue-box-lt-pad-box {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .tobo-cent {
    text-align: center;
  }
  .rt-lt-gr-box {
    padding-right: 2rem !important;
    padding-left: 2rem !important;
  }
  .bord-10-rad {
    border-radius: 10px;
    padding-top: 3rem !important;
  }
}
@media (min-width: 770px) {
  .tp-menu-show {
    display: block;
  }
  .sh-img {
    display: none;
  }
}
.sticky {
  position: fixed;
  top: 0;
  width: 100%;
}
.sticky + .content {
  padding-top: 60px;
}
.mar-left-log {
  margin-left: 30px;
}

/*----------------------------------2300-----------------------*/

@media (max-width: 768px) {
  .left-img {
    background-image: none;
    background-repeat: no-repeat;
    background-position: right bottom;
  }
  .cnt-test {
    text-align: center;
  }
  .slide-personal-intro {
    background-image: url(../img/head-bg.webp);
    background-size: cover;
    margin-top: 65px;
  }
  .center-whole {
    text-align: center;
  }
  .award-item-logo {
    margin-bottom: 0px;
    height: 9rem;
    display: block;
    overflow: hidden;
    align-items: center;
  }
  .high-fix {
    display: none;
  }
  .back-to-top {
    display: none;
  }
  .wid-40-per {
    width: 40%;
  }
}
/*----------------------------------2300-----------------------*/

@media (min-width: 2300px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1900px;
  }
  .wht-head-i {
    font-size: 8.5rem !important;
  }
  .abt-hd-res {
    font-size: 8.5rem !important;
  }
}
/*----------------------------------1920--------------------------*/
@media (min-width: 1920px) {
  .wht-head-i {
    font-size: 7.1rem;
  }
  .slide-title-sub-md {
    font-size: 2rem;
  }
  .col-black-test {
    color: #000 !important;
    font-weight: 500;
    font-size: 1.1rem;
  }
  .slide-container {
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    padding-top: 2.75rem;
    padding-bottom: 3.25rem;
    position: relative;
  }
  .font-one {
    font-size: 0.5rem;
  }
  .left-img {
    background-image: url(../img/left-img.png);
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }
  .abt-hd-res {
    font-size: 6.5rem !important;
  }
  .safety-secu-sm {
    font-size: 2.2rem !important;
    font-weight: 600 !important;
  }
  .still-have {
    color: #e0e0e0;
    font-size: 5rem;
  }
}

/*.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #FFF;
  border:solid 2px #FF0;
  z-index: 99;
}*/

@media (min-width: 576px) {
  .nav-link {
    padding-right: 1rem !important;
    padding-left: 0.5rem;
  }
}
@media (min-width: 2500px) {
  .left-img {
    background-image: url(../img/left-img2.png);
    background-repeat: no-repeat;
    background-position: 100% 100%;
  }
  /*.slide-intasd {
    background-image: url(../img/left-img2.png) !important;
    background-position: 50% 50%;
    
}*/
  .font-one {
    font-size: 0.5rem;
  }
}
.styled-checkbox {
  position: absolute;
  opacity: 0;
}
.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 25px;
  height: 25px;
  background: transparent;
  border: solid 1px #707070;
  border-radius: 5px;
}
.styled-checkbox:hover + label:before {
  background: #212121;
}
.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox:checked + label:before {
  background: #212121;
}
.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
  color: #00b8c3;
}
.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 8px;
  top: 13px;
  background: #00e3d9;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #00e3d9, 4px 0 0 #00e3d9, 4px -2px 0 #00e3d9,
    4px -4px 0 #00e3d9, 4px -6px 0 #00e3d9, 4px -8px 0 #00e3d9;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
/*----------------------------------*/
.styled-checkbox1 {
  position: absolute;
  opacity: 0;
}
.styled-checkbox1 + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox1 + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: transparent;
  border: solid 1px #707070;
}
.styled-checkbox1:hover + label:before {
  background: #212121;
}
.styled-checkbox1:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}
.styled-checkbox1:checked + label:before {
  background: #212121;
}
.styled-checkbox1:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox1:disabled + label:before {
  box-shadow: none;
  background: #ddd;
  color: #00b8c3;
}
.styled-checkbox1:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #00e3d9;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #00e3d9, 4px 0 0 #00e3d9, 4px -2px 0 #00e3d9,
    4px -4px 0 #00e3d9, 4px -6px 0 #00b8c3, 4px -8px 0 #00b8c3;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.back-to-top {
  position: fixed;
  bottom: 0px;
  z-index: 999;
}
.btn-success:not(:disabled):not(.disabled).active:focus,
.btn-success:not(:disabled):not(.disabled):active:focus,
.show > .btn-success.dropdown-toggle:focus {
  border: solid 1px #00e1d8;
  box-shadow: rgba(92, 165, 149, 0) 0 0 0 6px;
}
.btn-success:hover,
.btn-success.focus,
.btn-success:focus {
  box-shadow: rgba(92, 165, 149, 0) 0 0 0 6px;
  border: solid 1px #01a6e4;
  background: transparent;
  color: #00e1d8;
}
.btn-success {
  background-image: linear-gradient(to right, #00a3e4, #00e3d8);
  border-color: #01a6e4;
  border: solid 1px #00e0d9;
}
.input-form-bg {
  background: transparent;
  border: 0px;
  border-bottom: solid 1px #666;
  border-radius: 0px;
}
.exa-bg {
  background: transparent;
  border: 0px;
}
/*.slide-intasd {
    background-image: url(../img/left-img.png);
    background-position: 50% 50%;
    
}*/
.slide-intasd-abc {
  background-image: url(../img/right-img.webp);
  background-position: right bottom;
}
.font-one {
  font-size: 0.7rem;
}
.sub-bg-two {
  background: linear-gradient(
    90deg,
    rgb(245, 245, 245) 50%,
    rgb(255, 255, 255) 50%
  );
  z-index: 2;
}
.building-svg {
  position: absolute;
  bottom: 0px;
  right: 0px;
}
.building-left {
  position: absolute;
  bottom: 0px;
  left: 0px;
}
.btm-conti-rt {
  text-align: right;
}
.logo-wi-mob {
  width: 150px;
}
.safe-ico-pad {
  padding-left: 3rem;
}
.font-wei {
  font-weight: 400;
  font-size: 0.9rem;
}
/*---------------inner-pages---CSS---------------------*/
.max-width-modal {
  width: 100%;
}
.inner-header {
  position: absolute;
  top: 50%;
  z-index: 21;
}
.white-inner-txt {
  color: #fff;
  font-weight: bold;
  font-size: 3.5rem;
}
.white-inner-p {
  color: #fff;
  font-weight: 500;
  font-size: 1rem;
}
.inner-para-pad {
  padding: 18% 6rem 7rem 0rem;
}
.ptb-inner {
  padding: 5rem 0rem;
}
.py-6 {
  padding: 5rem 0rem;
}
.box-bord-gr {
  border: solid 1px #e0e0e0;
  position: relative;
}
.box-bord-gr-one {
  border: solid 1px #e0e0e0;
  position: absolute !important;
  background-color: #ff0;
  display: none;
}
.font-in-head {
  font-size: 3.5rem;
  font-weight: 700;
  color: #e0e0e0;
  letter-spacing: 3px;
}
.ptb-inner {
  padding: 6rem 0rem 0rem;
}
.inner-line {
  line-height: 1.8rem;
  font-size: 0.9rem;
}
.alerts-txt {
  font-weight: 800;
}
.pr-ann {
  padding-right: 8rem !important;
}
.pr-ann-left {
  padding-left: 8rem !important;
}
.al-tp {
  padding: 4.4rem 0rem;
}
.btn-primary {
  background: transparent;
  border-color: #21dfde;
  border: solid 1px #21dfde;
  color: #21dfde;
}
.btn-primary {
  transition: box-shadow 0.3s ease-in-out;
  transition-delay: 0;
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-image: linear-gradient(to right, #00a3e4, #00e3d8);
  border-color: #00d6db;
}
.btn-primary:hover {
  color: #fff;
  background-image: linear-gradient(to right, #00a3e4, #00e3d8);
  border-color: #00e3d8;
}
.bg-footer-main {
  background-color: #212121;
}
.bg-footer-dk {
  background-color: #212121;
  padding-left: 10%;
}
.connect-bg {
  background-color: #f4f4f4;
}
.mb-2-btm {
  margin-bottom: 1rem !important;
}
.text-mini-mbile {
  text-align: left;
}
@media (max-width: 768px) {
  .inner-tp-pad {
    padding-top: 70px;
  }
  .inner-para-pad {
    padding: 2rem 1rem 1rem 0rem;
  }
  .homepage-personal1 .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .homepage-personal1 .slide-personal-projects .container {
    padding-right: 53px;
  }
  .plr-0px {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .wid-5-per {
    width: 5%;
  }
  .wid-60-per-in {
    width: 60%;
    padding-top: 1.5rem !important;
  }
  .text-mini-mbile {
    text-align: center;
  }
  .ptb-inner {
    padding: 0rem 0rem 0rem;
  }
  .al-tp {
    padding: 0rem 0rem;
  }
  .mb-know {
    margin-bottom: 3rem;
  }
  .top-det-sp {
    padding-top: 30px !important;
  }

  .tech-sp {
    font-size: 3rem;
    margin-bottom: 0px;
  }
  .mb-tech {
    margin-bottom: 0px;
    padding-top: 2rem !important;
  }
  .add-more-img-pad {
    padding: 0rem !important;
  }
  .pl-list-gen {
    padding-left: 1rem !important;
  }
  .table-review {
    display: block !important;
  }
  .center-textin {
    text-align: center;
  }
  .center-textin-lt {
    text-align: center !important;
  }
  .blog-head-top {
    padding-top: 0rem !important;
  }
  .inner-header-blog {
    position: absolute;
    top: 50% !important;
    z-index: 21;
    font-size: 1rem !;
  }
  .inner-header-blog h2 {
    font-size: 1.5rem !important;
  }
  .blog-serach-top {
    padding-top: 1rem !important;
  }

  .wid-30-blog {
    width: 30% !important;
  }
  .share-text-al {
    text-align: center !important;
  }
  .text-center-blog {
    text-align: center;
  }
  .text-justi-blog {
    text-align: justify;
  }
  .padd-top-blog-sin {
    padding-top: 30px;
  }
  .contact-wid {
    width: 100% !important;
  }
  .padd-rightuser {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
  .user-center-mid {
    text-align: center !important;
  }
  .design-btn-mo {
    position: fixed;
    bottom: 25% !important;
    left: 12% !important;
  }
  .round-fix-height {
    height: 145px !important;
  }

  .top-det-sp-in {
    padding-top: 125px !important;
  }

  .loader-container {
    padding-top: 50% !important;
  }
  .content a {
    transition: color 0.3s ease;
    margin-top: 60%;
  }
  .alert h4 {
    line-height: 2;
  }
  .alert h4 a {
    transition: color 0.3s ease;
    margin-top: 5%;
    display: block;
    font-size: 14px;
  }
  .white-inner-txt {
    color: #fff;
    font-weight: 700;
    font-size: 3rem !important;
  }
  .login-rt-gyap {
    padding-right: 5rem !important;
    padding-left: 0px !important;
  }
  .mar-lt-ico {
    margin-left: 10px;
    color: #212121;
    text-align: left;
    padding-top: 15px;
  }
  .input-tb-padd {
    padding: 0rem 6rem !important;
  }
  .wht-type-btm-gyap {
    margin-bottom: 1rem !important;
  }
  .continue-margin {
    margin-bottom: 100px;
  }
  .round-gradient1:hover {
    background-image: none;
    border-radius: 60px;
    color: #fff !important;
    border: solid 1px #e0e0e0 !important;
  }
  .fast-wid {
    width: 50%;
    text-align: center;
  }
  .fast-wi-btm {
    width: 100%;
    text-align: center;
  }
  .show-all-btm a {
    margin-top: 15px;
  }
  .slide-title1 {
    font-size: 4.3vmin;
    font-weight: bold;
    color: #1b2c38 !important;
    margin-bottom: 1rem;
  }

  .slide-title-sub {
    margin-bottom: 0.5rem;
    font-size: 2.375rem;
    font-family: "Montserrat", sans-serif;
    line-height: 1.45454545;
    color: #757575;
    font-weight: 400;
  }
  .text-just-abt {
    text-align: justify;
  }
  .abt-text-top-container {
    padding-top: 40px;
  }

  .subsc-lt-pad-mb {
    padding-left: 1rem !important;
  }
  .padd-top-howe {
    padding-top: 50px;
  }
  .fast-wid-inline {
    width: 30%;
    text-align: center;
  }
  .fast-wid-inline1 {
    width: 40%;
    text-align: center;
  }
  .slide-descr {
    color: #999;
    font-size: 1.1rem;
    line-height: 1.66666667;
  }
  .text-mid-cen {
    text-align: center;
  }
  .wid-25lt {
    width: 25%;
  }
  .wid-75lt {
    width: 75%;
  }
  .mar-bt-in {
    margin-bottom: 20px;
  }
  .design-text-top {
    padding-top: 0.8rem;
  }
  .learn-more-btn {
    position: absolute;
    z-index: 99;
    bottom: 0% !important;
    left: 62% !important;
  }
  .around-mob {
    width: 30%;
  }
  .around-mob1 {
    width: 70%;
    text-align: left;
    padding-top: 10%;
  }
  .wht-top-padin {
    padding-top: 5rem;
  }
  .wid-50-pers {
    width: 50%;
  }
  .safely-pad-top-gyap {
    padding-top: 1rem;
  }
  .appappan-top {
    padding-top: 0rem !important;
  }
  h2.mob-tit {
    font-size: 1.5rem;
    margin-bottom: 1rem !important;
  }
  h3.mob-tit {
    font-size: 0.8rem;
    margin-bottom: 1rem !important;
  }
  h4.mob-tit {
    font-size: 0.8rem !important;
    margin-bottom: 1rem !important;
  }
  .slide3 {
    top: 10% !important;
  }
  .buy-pad-lt-rt {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .arrow-small-ban {
    width: 60px;
  }
  .mb-top-5 {
    padding-top: 3rem !important;
  }
  .bot-mar {
    margin-bottom: 15px;
  }
  .bot-mar1 {
    padding-bottom: 20px;
  }
  .bg-top-gra-one {
    background-image: linear-gradient(to right, #00e2d8, #00a6e4);
    margin-top: 100px !important;
  }
  .top-priv {
    font-size: 0.7rem !important;
  }
  .top-priv-link {
    font-size: 0.7rem !important;
  }
  .offset-2,
  .offset-3 {
    margin-left: 0px;
  }

  .slide-personal-intro-inde {
    background-image: url(../img/top-head-inde1.png) !important;
    background-size: 100% !important;
  }
}
.slide3 {
  position: absolute;
  top: 25%;
  left: 15%;
}
.book {
  width: 100%;
  height: 100%;
  position: relative;
}
.info {
  width: 98%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  background: rgba(0, 0, 0, 0.8);
  display: none;
  text-align: center !important;
}
.book .image {
  text-align: center;
  position: relative;
  top: 50%;
}
.book:hover .info {
  display: block;
}
.font-hd-on {
  font-size: 1.35rem;
}
.input-tb-padd {
  padding: 5rem 6rem;
}
.stay-marg {
  margin-bottom: 1.9rem;
}
.ic-arrow-lt {
  margin-left: -5px;
}
.subs-font {
  font-size: 1.5rem;
}
.pt-8-per-but {
  padding-top: 8%;
}
.shop-btn {
  padding: 5rem 0rem;
}
.bg-in-lt {
  background-color: #f4f4f4;
}
select.form-control {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA2NCA2NCc+PHBhdGggZmlsbD0nIzAwMDAwMCcgZD0nTTMyLDQ4LjFjLTEuMywwLTIuNC0wLjUtMy41LTEuM0wwLjgsMjAuN2MtMS4xLTEuMS0xLjEtMi43LDAtMy43YzEuMS0xLjEsMi43LTEuMSwzLjcsMEwzMiw0Mi44bDI3LjUtMjYuMSBjMS4xLTEuMSwyLjctMS4xLDMuNywwYzEuMSwxLjEsMS4xLDIuNywwLDMuN0wzNS41LDQ2LjVDMzQuNCw0Ny45LDMzLjMsNDguMSwzMiw0OC4xeicvPjwvc3ZnPg==);
  background-repeat: no-repeat;
  background-size: 1rem 1rem;
  background-position: 90% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.border-who {
  border: solid 1px #e0e0e0;
}
.list-in {
  list-style: url(../img/tick.png);
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.9rem;
}
.font-gen {
  font-size: 1rem;
  line-height: normal;
  margin-bottom: 1rem;
}
.font-nor {
  font-size: 0.8rem;
}
.gred-in-bg {
  background-image: linear-gradient(to right, #00a5e5, #00e2d8);
}
.grad-bold {
  font-weight: bold;
  color: #fff;
}
.white-but {
  border: solid 1px #fff !important;
  color: #fff !important;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0);
}
.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #00d6db;
  border-color: #00d6db;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0);
}
.add-to-cart-btn {
  color: #00a6e6;
  font-weight: 500;
}
.add-to-cart-btn a {
  color: #00a6e6;
  font-weight: 500;
}
.btm-bo-air {
  border-bottom: solid 1px #e0e0e0;
}
.top-det-sp {
  padding-top: 170px;
}

/*--------------inner-container--------------*/
@media (min-width: 1910px) {
  .inner-safely .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 1200px !important;
  }
  .right-padd-offer {
    padding-right: 7%;
  }
  .font-in-head-inn {
    font-size: 6rem;
    font-weight: 700;
    color: #e0e0e0;
    letter-spacing: 0px;
    line-height: 1;
  }
  .ml-minus {
    margin-left: -13px;
  }
}
/*--------------inner-container-end-------------*/
.mar-lt-ico {
  margin-left: 10px;
  color: #212121;
}
.award-item-logo-in {
  margin-bottom: 0px;
  display: flex;
  overflow: hidden;
  align-items: center;
}
.pl-5-inner {
  padding-left: 6rem !important;
}
.dimesions-btm {
  margin-bottom: 2rem;
}
.what-are-color {
  color: #212121;
  font-weight: 500;
  font-size: 1.5rem;
}
.round-gradient1 {
  border: solid 1px #e0e0e0;
  border-radius: 60px;
}
/*----------------------------------*/
.styled-checkbox2 {
  position: absolute;
  opacity: 0;
}
.styled-checkbox2 + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.styled-checkbox2 + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  background: transparent;
  border: solid 1px #d6d6d6;
}
.styled-checkbox2:hover + label:before {
  background: #fff;
}
.styled-checkbox2:focus + label:before {
  box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.12);
}
.styled-checkbox2:checked + label:before {
  background: #fff;
  border: solid 1px #00d4dc;
}
.styled-checkbox2:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.styled-checkbox2:disabled + label:before {
  box-shadow: none;
  background: #fff;
  color: #00b8c3;
}
.styled-checkbox2:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: #fff;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 #00e3d9, 4px 0 0 #00e3d9, 4px -2px 0 #00e3d9,
    4px -4px 0 #00e3d9, 4px -6px 0 #00b8c3, 4px -8px 0 #00b8c3;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
.sec-head {
  color: #757575;
}
.mb-6-mob {
  margin-bottom: 6rem;
}
.go-ba {
  color: #00a6e6;
  font-size: 0.8rem;
  padding-top: 0.8rem;
}
.wid-50-per {
  width: 50%;
}
.pt-top-gyap {
  padding-top: 3rem;
}
.top-list-bread {
  font-size: 0.675rem;
  margin-bottom: 0px !important;
  color: #bdbdbd;
  font-weight: 400;
}

.top-list-bread a {
  color: #bdbdbd;
  text-decoration: none;
}
.top-list-bread a:hover {
  color: #00a6e6;
  text-decoration: none;
}
.blue-acti {
  color: #00a6e6 !important;
}
.white-but-qu {
  border: solid 1px #e0e0e0 !important;
  color: #000 !important;
  padding: 0.3rem 2.5rem;
}
.white-but-qu:hover {
  border: solid 1px #e0e0e0 !important;
  color: #fff !important;
  padding: 0.3rem 2.5rem;
}
.gro-btn {
  border-radius: 6px;
  padding: 0.3rem 1.5rem;
  border: solid 1px #e0e0e0;
  color: #000;
}
.round-fix-height {
  min-height: 145px !important;
  height: auto !important;
}
.edit-pack {
  font-size: 0.7rem;
  font-weight: 500;
}
.prod-inclu {
  font-size: 1rem;
  font-weight: 600;
}
.included-detail {
  line-height: 1.8rem;
  font-size: 0.7rem;
}
.font-hd-on-in {
  font-size: 1.8rem;
}
/*------------accordian---------------------------*/

.accordian {
  width: 100%;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
  margin: 5% auto;
  color: #b5b5b5;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.accordian .title {
  background-color: #fff;
  color: #212121;
  padding: 1rem 0rem;
  margin: 0;
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  font-weight: 600;
  transition: all 0.25s ease;
}
.accordian .title:hover {
  background-color: rgba(0, 0, 0, 0.005);
}
.accordian ul li:last-child .title {
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
}
.accordian ul ul li a {
  display: block;
  background-color: #eee;
  padding: 1rem;
  color: #777;
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
  padding-top: 2rem;
}
.accordian ul ul li a:hover {
  background-color: #55acee;
  color: #fff;
}
.accordian ul ul {
  display: none;
}
.open {
  transform: rotate(45deg);
  transition: all 0.25s ease;
}
.accordian ul ul li:last-child a {
  border-bottom: 0px solid rgba(0, 0, 0, 0.05);
}
.fa {
  transition: all 0.25s ease;
  float: right;
}
.accordian h3 {
  cursor: pointer;
  font-size: 1.2rem;
}
.acc-in {
  margin: 0;
  padding: 0;
}
.acc-in li {
  list-style: none;
  padding: 0;
}
.acc-in a {
  text-decoration: none;
  transition: all 0.25s ease;
}
.icon-size-in {
  font-size: 0.8rem !important;
  padding-top: 0.5rem;
}
.blk-head-in {
  color: #000;
  font-size: 0.9rem;
}
.air-weight-bold {
  font-weight: 600;
}
.top-det-sp-in {
  padding-top: 80px;
}
.qty-text {
  color: #747474;
}
.bg-light-inner {
  background-color: #f4f4f4;
}
.choose-sub-in {
  font-size: 1.2rem;
  font-weight: 700;
}
.bord-line {
  border: solid 2px #e9e9e9;
  border-radius: 5px;
}
.bord-line:hover {
  border: solid 2px #00e4d8;
  border-radius: 5px;
}
.blue-dis {
  color: #00a6e6;
  font-weight: 600;
}
.shipping-bor {
  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;
}
.bg-tran-in {
  background: transparent;
}
.border-0-px {
  border: 0px;
}
.color-blk-in {
  color: #000;
}
.font-we-600 {
  font-weight: 600;
}
.border-bottom-0-in {
  border-bottom: 0px;
}
.border-bottom-0-px {
  border-bottom: 1px solid #e0e0e0;
}
.font-one-re {
  font-size: 1rem;
}
.font-one-edit {
  font-size: 0.7rem;
}
.border-rad-0-px {
  border-radius: 0px;
}
.bg-summa {
  background-color: #f4f4f4;
}
.motion-bord {
  border-top: solid 1px #e0e0e0;
  border-bottom: solid 1px #e0e0e0;
  padding: 1rem 0rem;
}
.text-dark-gr {
  color: #999;
}
.dis-bl-text {
  color: #00a6e6;
  font-weight: 700;
  font-size: 0.8rem;
  border-bottom: solid 1px #e0e0e0;
  padding-bottom: 1rem;
}
.font-one-edit-in {
  font-size: 1.2rem;
}
.wid-30-per {
  width: 30%;
}
.card-bg-rev {
  background: transparent !important;
  border: 0px !important;
}
.bord-tp-th {
  border-top: 0px !important;
}
.table-review {
  display: inline-table;
}
.btn-pad-lt-rt {
  padding: 0.7rem 0.5rem;
}
.abt-head-inner {
  font-size: 1.5rem;
  line-height: inherit;
}
.acc-head-faq {
  font-size: 0.9rem !important;
}
.still-have {
  color: #e0e0e0;
  font-size: 3rem;
}
.center-textin-lt {
  text-align: right;
}
.ftr-lnk li {
  color: #fff !important;
  text-decoration: none;
}
.ftr-lnk li a {
  color: #fff;
  text-decoration: none;
}
.ftr-lnk li a:hover {
  color: #00dbda !important;
  text-decoration: none;
}
.legal-txt-col {
  color: #fff;
}
.legal-txt-col a {
  color: #fff;
  text-decoration: none;
}
.legal-txt-col a:hover {
  color: #00deda;
  text-decoration: none;
}
.whi-blog {
  color: #fff;
  font-weight: 500;
}
.border-0-blog {
  border: 0px;
}
.blog-head-top {
  padding-top: 3rem;
}
.inner-header-blog {
  position: absolute;
  top: 50%;
  z-index: 21;
}
.blog-serach-top {
  padding-top: 3rem;
}
.more-art {
  border-bottom: solid 1px #979797;
  font-size: 1.2rem;
}
.share-text-al {
  text-align: right;
}
.contact-wid {
  width: 80%;
}
.bord-cont-rad {
  border-radius: 0px;
}
.appli-colo-hm {
  color: #ccc;
}

/*-----------my-account-------------------*/
.user-bg-bl {
  background-color: #212121;
}
.username-txt-white {
  color: #fff;
}
.edit-prof-text {
  color: #0591bf;
}
.edit-prof-text a {
  color: #0591bf;
}
.edit-prof-text a:hover {
  color: #0591bf;
}
.col-black-ode {
  color: #000;
}
.col-black-ode a {
  color: #000;
  text-decoration: none;
}
.col-black-ode a:hover {
  color: #1bb0e8;
  text-decoration: none;
}
.border-in-profile {
  border: solid 1px #e2e2e2;
}
.border-in-profile:hover {
  border: solid 1px #e2e2e2;
  -moz-box-shadow: 0 0 10px #999;
  -webkit-box-shadow: 0 0 10px #999;
  box-shadow: 0 0 10px #999;
}
.london-txt {
  background-color: #333;
  color: #fff;
}
.design-btn-mo {
  position: fixed;
  bottom: 50px;
  left: 40%;
}
.round-fix-height {
  height: 105px;
}
.secu-back-bg {
  background-color: #00a4e5; /* For browsers that do not support gradients */
  background-image: linear-gradient(to top right, #00a4e5, #00e1ea);
  border-radius: 10px;
}
.intex-white {
  color: #fff !important;
}
.intex-white a {
  color: #fff !important;
}
.intex-white a:hover {
  color: #fff !important;
}
.secu-col-hm {
  color: #00a6e5;
}
.wrapper-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}
.bg-round-secur-lt {
  background-color: #f5f5f5;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.bg-round-secur-rt {
  background-color: #f5f5f5;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}
.bg-safe-one {
  background-color: #f5f5f5;
}
.body-menu-opened .stick-3 {
  display: none;
}
.body-menu-opened .stick-2 {
  margin-top: 9px !important;
}
.loader-container {
  padding-top: 20% !important;
}
a,
a:hover {
  color: #00a6e6;
}
.wht-type-btm-gyap {
  margin-bottom: 3rem;
}
#mute {
  cursor: pointer;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 120px;
  right: 50px;
  border: 0px solid #000;
  background-size: cover;
  color: #ccc !important;
}
.wireless-head {
  color: #00b5e2;
  font-weight: 600;
  font-size: 1.4rem;
}
.no-cab-text {
  color: #00b5e2;
  font-size: 1.1rem;
}
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgb(51 175 228) !important;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 0.8rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.banner-text-tb-pad {
  padding-top: 8%;
  padding-bottom: 10%;
}
.pt-120 {
  padding-top: 8%;
}
.pb-120-btm {
  padding-bottom: 10%;
}
.py-1-grd-boc {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
/*  ----------min-2200px-wid---------------*/

@media (min-width: 1910px) {
  .pb-120-btm {
    padding-bottom: 19%;
  }
  .the-most-big {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .slide-title1 {
    font-size: 3.3vmin;
    font-weight: bold;
    color: #1b2c38 !important;
    margin-bottom: 1.6rem;
  }
  .pt-120 {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .slide-personal-intro-inde {
    background-image: url(../img/top-head-inde.png);
    background-size: 90% !important;
  }
}

.bg-rev-bl {
  background-color: #212121;
}
.dark-blue-lt {
  background-color: #f07d00;
}
.one-text {
  font-size: 8rem;
  color: #fff;
  line-height: normal;
}
.month-free {
  font-size: 2rem;
  color: #fff;
  line-height: normal;
}
.exp-text-lt {
  font-size: 1.5rem;
  color: #fff;
  line-height: normal;
}
.exp-padd-top {
  padding-top: 2.5rem;
}

.rount-month-pos {
  position: absolute;
  right: 50px;
  z-index: 999;
  top: 35px;
}
.round-top-btm-pad {
  padding-top: 7rem;
  padding-bottom: 7rem;
}

@media screen and (min-device-width: 1000px) and (max-device-width: 1300px) {
  .rount-month-pos {
    position: absolute;
    right: 50px;
    z-index: 999;
    top: 57px;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 990px) {
  .rount-month-pos {
    position: absolute;
    right: 50px;
    z-index: 999;
    top: 90px;
  }
}

@media (min-width: 1910px) {
  .pb-120-btm {
    padding-bottom: 19%;
  }
  .rount-month-pos {
    position: absolute;
    right: 50px;
    z-index: 999;
    top: 0px;
  }
}
/*  ----------min-2200px-wid---------------*/

@media (min-width: 1910px) {
  .the-most-big {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .slide-title1 {
    font-size: 3.3vmin;
    font-weight: bold;
    color: #1b2c38 !important;
    margin-bottom: 1.6rem;
  }
  .pt-120 {
    padding-top: 15%;
    padding-bottom: 15%;
  }
  .slide-descr {
    color: #999;
    font-size: 1.2rem;
    line-height: 1.66666667;
  }
}
.form-control {
  border: 0;
  border-bottom: 1px solid #c9ccd7;
  font-weight: 400;
  font-size: 0.875rem;
}
.pt-wht-uniq {
  padding-top: 3rem !important;
}
/*------------------------------------------------bg-bl-des-----------------*/
.des-bg-back {
  background-color: #00aee3;
  color: #fff;
  border-radius: 5px;
}
.crime-bg-nw {
  background-image: url(../img/crime-uk-1.jpg);
  background-size: cover;
}
.crime-in-top-fix {
  padding-top: 20%;
}
.crime-in-btm-fix {
  padding-bottom: 10%;
}
.dark-bg-back {
  background-color: #212121;
}
.around-text {
  color: #fff !important;
  line-height: 1.5;
}
.prod-inc {
  font-weight: bold;
  color: #212121;
  font-size: 1rem;
  margin-bottom: 1rem;
  line-height: 1.5;
}
.secu-sta {
  font-size: 1.8rem;
  font-weight: bold;
  color: #202020;
  margin-bottom: 1rem;
  line-height: 1.5;
}
.wr-round-border {
  border: solid 1px #bdbdbd;
  border-radius: 20px;
}
.price-fon {
  font-size: 1.6rem;
  font-weight: bold;
}
.font-min {
  font-size: 1rem;
}
.three-row-pdd {
  padding-top: 7%;
  padding-bottom: 7%;
}
.des-in-text {
  font-size: 1.4rem;
  color: #767676 !important;
  font-weight: bold;
  line-height: 1.5;
}
.learn-more-btn {
  position: absolute;
  z-index: 99;
  bottom: 10%;
  left: 75%;
}
.learn-more-btn1 {
  position: absolute;
  z-index: 99;
  bottom: 10%;
  left: 12%;
}
.buy-pad-lt-rt {
  padding-left: 50px;
  padding-right: 50px;
}
.border-lef-right-radiius {
  border-top-right-radius: 17px;
  border-top-left-radius: 17px;
}
.line-trg {
  text-decoration: line-through;
}
.red-txt {
  color: #d11111;
  font-size: 1.9rem;
}
.banner-top-gyap {
  padding-top: 6rem !important;
}
.back-grey-intr {
  background-color: #f5f5f5;
}
.banner-top-gyap-bg {
  padding-top: 6rem !important;
}
.appappan-top {
  padding-top: 3rem;
}
.banner-bottom-gyap-bg {
  padding-bottom: 6rem !important;
}
.font-suc-btm {
  font-size: 1.5rem;
}
.late-buzz {
  color: #999 !important;
  font-size: 1.1rem;
  font-weight: normal;
}
.zoom {
  transition: transform 0.2s;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
.zoom:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
}
.how-it-pos-nw {
  position: absolute;
  bottom: 10%;
  right: 20%;
}
.get-offer {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
  font-size: 0.8rem;
  font-weight: 600;
}
.input-bord {
  border-top-right-radius: 20px !important;
  border-bottom-right-radius: 20px !important;
  height: 41px;
  background-color: transparent;
  border: solid 1px #fff;
  color: #fff;
}
.bg-top-gra-one {
  background-image: linear-gradient(to right, #00e2d8, #00a6e4);
  margin-top: 74px;
}
.text-col-offer {
  color: #fff;
}
.para-text p {
  font-size: 1rem;
  line-height: 1.66666667;
  color: #212121 !important;
}
.top-priv {
  color: #fff;
  font-size: 0.55rem;
}
.top-priv-link {
  color: #fff;
  font-size: 0.5rem;
  text-decoration: underline;
}
a.round-butt {
  border: 1px solid #00a6e4;
  border-radius: 15px;
  padding: 5px 10px;
  font-size: 0.875rem;
  /*border-color: #00a6e4;*/
  color: #00a6e4;
  margin-right: 15px;
  display: inline-block;
  float: right;
}
@media (max-width: 393px) {
  .mb-pt-5 {
    margin-top: 5px;
  }
}
.bg-saf-blue {
  background-color: #29abe2 !important;
}
.prof-name-text {
  color: #ffffff !important;
  font-size: 1.1rem;
}
.sidebar .nav .nav-item .nav-link {
  color: #ffffff;
}
.trustslider .carousel-indicators {
  bottom: -30px;
}

.click-banner {
  color: #747474;
  font-size: 1.25rem;
  margin-top: 5rem;
}
.click-banner-btm {
  color: #747474;
  font-size: 1.05rem;
  font-style: italic;
}
.inde-top {
  color: #e81478;
}
.buy-tp {
  padding: 0.5rem 2.5rem;
}
.btn-pink {
  background-image: linear-gradient(to right, #e81478, #e81478);
  border-color: #01a6e4;
  border: solid 0px;
}
.slide-personal-intro-inde {
  background-image: url(../img/top-head-inde.png);
  background-size: cover;
}
.acc-title {
  font-size: 2.5rem !important;
  background: linear-gradient(to right, #00a4e5 0%, #00e4d7 30%);
  background-image: linear-gradient(to right, #00a4e5 0%, #00e4d7 50%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.col-faq-bl {
  color: #000;
}
.bg-trans {
  background-color: transparent !important;
  display: inline !important;
}
